import React from 'react';
import PropTypes from 'prop-types';

function Form({
  submitted, children, containerProps, actions, ...otherProps
}) {
  return (
    <div
      {...containerProps}
    >
      {submitted
        ? (
          <div className="demo-form--submitted">
            Thanks for submitting your request.
          </div>
        )
        : (
          <form {...otherProps}>
            {children}
          </form>
        )
      }
      {!submitted && actions}
    </div>
  );
}

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  actions: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  containerProps: PropTypes.object,
  submitted: PropTypes.bool.isRequired,
};

Form.defaultProps = {
  containerProps: {},
};

export default Form;
