import React from 'react';
import ArrowLeft from '@material-ui/icons/ArrowLeft';
import ArrowRight from '@material-ui/icons/ArrowRight';
import IconButton from '@material-ui/core/IconButton';
import D3 from '../assets/images/d3.jpg';
import Fondation from '../assets/images/fondation.jpg';
import PolyFab from '../assets/images/polyFab.jpg';
import CenTech from '../assets/images/cenTech.jpg';
import Ets from '../assets/images/ets.jpg';

let intervalLeft;
let intervalRight;

const scrollToLeft = () => {
  document.getElementById('brands').scrollLeft -= 1;
};

const scrollToRight = () => {
  document.getElementById('brands').scrollLeft += 1;
};

const onMouseEnterLeft = () => {
  intervalLeft = setInterval(scrollToLeft, 5);
};

const onMouseLeaveLeft = () => {
  clearInterval(intervalLeft);
};

const onMouseEnterRight = () => {
  intervalRight = setInterval(scrollToRight, 5);
};

const onMouseLeaveRight = () => {
  clearInterval(intervalRight);
};

const Featured = () => (
  <div id="featured">
    <IconButton
      onMouseEnter={onMouseEnterLeft}
      onMouseLeave={onMouseLeaveLeft}
      id="left-scroll"
      aria-label="Delete"
    >
      <ArrowLeft className="svg_icons" />
    </IconButton>
    {/* eslint-disable-next-line react/no-unescaped-entities */}
    <div className="brands" id="brands">
      <img className="brands_d3" src={D3} alt="" />
      <a href="https://www.montrealinc.ca/"><img className="brands__fondation" src={Fondation} alt="" /></a>
      <img className="brands__polyTab" src={PolyFab} alt="" />
      <img className="brands__cenTech" src={CenTech} alt="" />
      <img className="brands__ets" src={Ets} alt="" />
    </div>
    <IconButton
      onMouseEnter={onMouseEnterRight}
      onMouseLeave={onMouseLeaveRight}
      id="right-scroll"
      aria-label="Delete"
    >
      <ArrowRight className="svg_icons" />
    </IconButton>
  </div>
);

export default Featured;
