import React from 'react';
import Paper from '@material-ui/core/Paper';
import ScrollableContainer from './ScrollableContainer';

const Services = () => (
  <ScrollableContainer
    componentId="about"
  >
    <Paper id="about">
      <h2 className="header">Solutions</h2>
      <div className="content">
        <div className="about__card">
          <h2>Anomaly and damage detection</h2>
          {/* eslint-disable-next-line no-tabs,max-len,no-mixed-spaces-and-tabs */}
          Automatic solution for anomaly detection from images of civil structures, such as towers, posts, etc.
        </div>
        <div className="about__card">
          <h2>Feature extraction</h2>
          {/* eslint-disable-next-line max-len */}
          Automatic solution for feature extraction from 3D models (point clouds) of civil facilities such as roads, towers, etc.
        </div>
      </div>
    </Paper>
  </ScrollableContainer>
);

export default Services;
