import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import withStyles from '@material-ui/core/styles/withStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import $ from 'jquery';
import style from '../assets/jss/sidebar';

class Sidebar extends Component {
  static defaultProps = {};

  static propTypes = {};

  state = {
    open: false,
  };

  toggleDrawer = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  handleClickItem = (id) => {
    this.toggleDrawer();
    const ref = document.getElementById(id);
    const top = ref.getBoundingClientRect().top - document.body.getBoundingClientRect().top;

    $('html, body').animate({
      scrollTop: top,
    }, 1000);
  };

  render() {
    const { open } = this.state;
    const { classes } = this.props;
    return (
      <>
        <MenuIcon
          id="menu-icon"
          className={classes.menuIcon}
          onClick={this.toggleDrawer}
        />
        <Drawer
          id="sidebar"
          open={open}
          onClose={this.toggleDrawer}
          classes={{ paper: classes.sidebar }}
        >
          <div
            className={classes.list}
            role="presentation"
          >
            <List>
              <ListItem
                onClick={() => this.handleClickItem('home')}
                button
              >
                <ListItemText className={classes.listItem}>
                  <Typography type="body2" className={classes.listItemText}><b>Definitechs</b></Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => this.handleClickItem('our-jobs')}
                button
              >
                <ListItemText className={classes.listItem}>
                  <Typography type="body2" className={classes.listItemText}>About Us</Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => this.handleClickItem('product')}
                button
              >
                <ListItemText className={classes.listItem}>
                  <Typography type="body2" className={classes.listItemText}>Services</Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => this.handleClickItem('demo')}
                button
              >
                <ListItemText className={classes.listItem}>
                  <Typography type="body2" className={classes.listItemText}>Demo</Typography>
                </ListItemText>
              </ListItem>
              <ListItem
                onClick={() => this.handleClickItem('footer')}
                button
              >
                <ListItemText className={classes.listItem}>
                  <Typography type="body2" className={classes.listItemText}>Contact Us</Typography>
                </ListItemText>
              </ListItem>
            </List>
          </div>
        </Drawer>
      </>
    );
  }
}

Sidebar.defaultProps = {
  classes: {},
};

Sidebar.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
};

export default withStyles(style)(Sidebar);
