import axios from 'axios';
import { development } from '../config';


if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = `${development.protocol}://${development.httpServer.host}:${
    development.httpServer.port}`;
}

axios.defaults.withCredentials = 'include';
axios.interceptors.response.use(response => response,
  error => Promise.reject(error));

export default axios;
