import React from 'react';
import MaterialUiTab from '@material-ui/core/Tab';
import withStyles from '@material-ui/core/es/styles/withStyles';
import { PropTypes } from 'prop-types';
import style from '../assets/jss/tab';


function Tab({ active, classes, ...otherProps }) {
  return (
    <MaterialUiTab className={`${classes.tab} ${active ? classes.activeTab : ''}`} {...otherProps} />
  );
}

Tab.defaultProps = {
  classes: {},
  active: false,
};

Tab.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  active: PropTypes.bool,
};

export default withStyles(style)(Tab);
