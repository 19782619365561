import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TabsComponent from '../components/Tabs';

function Tabs({ activeTab }) {
  return (
    <TabsComponent
      activeTab={activeTab}
    />
  );
}

Tabs.propTypes = {
  activeTab: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  activeTab: state.tab.activeTab,
});

export default connect(mapStateToProps)(Tabs);
