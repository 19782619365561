import React from 'react';
import PropTypes from 'prop-types';
import MaterialUiTextField from '@material-ui/core/TextField';


function TextField(props) {
  const {
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
  } = props;
  return (
    <MaterialUiTextField
      style={{marginTop: '15px' }}
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      variant="outlined"
      {...input}
      {...custom}
    />
  );
}

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  input: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  meta: PropTypes.object.isRequired,
};

export default TextField;
