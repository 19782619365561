import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import tab from './tab';
import demo from './demo';

export default combineReducers({
  form: formReducer,
  demo,
  tab,
});
