import React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import { create } from 'jss';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import Tabs from '../containers/Tabs';
import Footer from './Footer';
import Home from './Home';
import Product from './Product';
import About from './About';
import Featured from './Featured';
import Demo from './Demo';
import Sidebar from './Sidebar';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";


import '../assets/scss/index.scss';
import Services from './Services';
import OurJobs from './OurJobs';
import PortalProduct from './PortalProduct';
import axios from '../apis/apiclient';
import Leadership from "./Leadership";

const styleNode = document.createComment('jss-insertion-point');
document.head.insertBefore(styleNode, document.head.firstChild);

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // We define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: 'jss-insertion-point',
});

class App extends React.Component {
  componentDidMount() {
    axios.post('apis/visit-report');
  }

  render() {
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <div className="App">
          <Router>
            <Switch>
              <Route path="/leadership">
                <Tabs />
                <Sidebar />
                <Leadership />
              </Route>
              <Route path="/">
                <Tabs />
                <Sidebar />
                <Home />
                <OurJobs />
                <About />
                <Product />
                <Services />
                <PortalProduct />
                <Featured />
                <Demo />
              </Route>
            </Switch>
          </Router>
          <Footer />
        </div>
      </JssProvider>
    );
  }
}

export default App;
