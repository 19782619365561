export default {
  sidebar: {
    backgroundColor: '#212121',
  },
  list: {
    width: '250px',
    color: 'white',
  },
  listItem: {
    color: 'white',
  },
  listItemText: {
    color: 'white',
  },
  menuIcon: {
    position: 'fixed',
    zIndex: '1000',
    top: '10px',
    left: '10px',
    cursor: 'pointer',
  },
};
