export default {
  tabs: {
    backgroundColor: 'rgba(256,256,256, 0)',
    position: 'fixed',
    zIndex: '1000',
    top: '0px',
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    paddingLeft: '20px',
    paddingRight: '20px',
    color: 'white',
    boxShadow: 'none',
  },
  activeTabs: {
    backgroundColor: 'rgba(256,256,256, 1)',
    boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    color: 'black',
    '& $logoTab': {
      color: '#336665',
    },
    '& $logoImage': {
      filter: 'none',
    },
    '& $logo': {
      color: '#336665',
    },
  },
  tab: {
    border: '1px solid rgba(1,1,1,0)',
    textTransform: 'capitalize',
    borderRadius: '50vh',
    fontWeight: 'bold',
    fontSize: '16px',
    minWidth: '100px',
  },
  logoTab: {
    borderRadius: '50vh',
    minWidth: '120px',
    textTransform: 'capitalize',
  },
  activeTab: {
    border: '1px solid #336665',
    color: '#336665',
  },
  tabIndicator: {
    opacity: '0',
    zIndex: '-1',
  },
  logo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    color: 'white',
  },
  logoImage: {
    width: '48px',
    marginRight: '10px',
    filter: 'brightness(0) invert(1)',
  },
};
