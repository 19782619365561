import React from 'react';
import Paper from '@material-ui/core/Paper';
import ScrollableContainer from './ScrollableContainer';
import AccurateIcon from '../assets/images/Icons_Accurate.svg';
import AutomaticIcon from '../assets/images/Icons_Automatic.svg';
import FastIcon from '../assets/images/Icons_Fast.svg';


const About = () => (
  <ScrollableContainer
    componentId="our-jobs"
  >
    <Paper id="about">
      <h2 className="header">AI-based Surveying and Inspection of Infrastructures</h2>
      <div className="content">
        <div className="about__card">
          <img src={AutomaticIcon} className="about__icon" alt="Automatic Icon" />
          <h2>Fully-Automatic</h2>
          {/* eslint-disable-next-line no-tabs,max-len,no-mixed-spaces-and-tabs */}
          Level of automation directly impacts cost, time and accuracy. With our fully automatic technology, manual process is minimized.
        </div>
        <div className="about__card">
          <img src={AccurateIcon} className="about__icon" alt="Automatic Icon" />
          <h2>Accurate and reliable</h2>
          {/* eslint-disable-next-line max-len */}
          With sophisticated quality control architecture, the results are ensured to be accurate and flawless.

        </div>
        <div className="about__card">
          <img src={FastIcon} className="about__icon" alt="Automatic Icon" />
          <h2>Fast</h2>
          {/* eslint-disable-next-line no-tabs,max-len,no-mixed-spaces-and-tabs */}
          Depending on type of the structure, we provide results up to 7x faster than conventional techniques.
        </div>
      </div>
    </Paper>
  </ScrollableContainer>
);

export default About;
