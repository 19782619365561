import React from 'react';
import ScrollableContainer from './ScrollableContainer';
import Tower3d from '../assets/images/tower-3d.png';

const Product = () => (
  <ScrollableContainer
    componentId="product"
  >
    <div
      id="product"
    >
      <div className="images">
        <img id="tower-image" src={Tower3d} alt="product" />
      </div>
      <div className="content">
        <h2 className="header">Intelligent feature extraction and anomaly detection</h2>
        <p>
          {/* eslint-disable-next-line max-len */}
          We have developed various AI-based technologies for reliable automation of infrastructures surveying and inspection processes. Our technologies enable us to automatically segment and separate various geometric object in point clouds. This leads to significantly reduce the cost, time and risks involved with manual process while increasing reliability and accuracy that are expected by out customers.
        </p>
      </div>
    </div>
  </ScrollableContainer>
);

export default Product;
