import { ACTIVATE_TAB } from '../type/types';

const initialState = {
  activeTab: 'home',
};

const tabs = (state = initialState, action) => {
  if (action.type === ACTIVATE_TAB) {
    return Object.assign({}, state, {
      activeTab: action.payload,
    });
  }
  return state;
};

export default tabs;
