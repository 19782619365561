import React from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ScrollableContainer from './ScrollableContainer';
import LinkedIn from '../assets/images/linkedin.svg';


class LeadershipCard extends React.Component {
  render() {
    return (
      <div>
        <Card className="leadership-card">
          <CardActionArea>
            <img className="leadership-img" src={this.props.imgLink} alt="img" />
            <CardContent>
              <a href={this.props.linkedInURL}>
                <img className="linked-in-img" src={LinkedIn} alt="linked-in" />
              </a>
              <Typography gutterBottom variant="h5" component="h2">
                {this.props.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {this.props.text}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </div>
    );
  }
}

export default LeadershipCard;
