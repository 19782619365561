import { DEMO_FORM_SUBMITTED } from '../type/types';

const initialState = {
  submitted: false,
};

const demo = (state = initialState, action) => {
  if (action.type === DEMO_FORM_SUBMITTED) {
    return Object.assign({}, state, {
      submitted: true,
    });
  }
  return state;
};

export default demo;
