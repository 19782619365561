import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ScrollableContainer from './ScrollableContainer';
import ProductImage from '../assets/images/product.png';


const PortalProduct = () => (
  <ScrollableContainer
    componentId="product"
  >
    <div
      id="product-right"
    >
      <div className="content-container">
        <div className="content">
          <h2 className="header">Data Management and Visualiztion</h2>
          <List className="list">
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord className="list-circle" />
              </ListItemIcon>
              <ListItemText primary="Scalable portal for customer interaction" />
            </ListItem>
            <ListItem href="#simple-list">
              <ListItemIcon>
                <FiberManualRecord className="list-circle" />
              </ListItemIcon>
              <ListItemText primary="Efficient management of data and results" />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <FiberManualRecord className="list-circle" />
              </ListItemIcon>
              <ListItemText primary="Automatic report generation" />
            </ListItem>
          </List>
        </div>
      </div>
      <div className="images">
        <img id="product-image" src={ProductImage} alt="product" />
      </div>
    </div>
  </ScrollableContainer>
);

export default PortalProduct;
