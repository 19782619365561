import { get, set } from 'lodash';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['name', 'email', 'subject', 'message'];

  requiredFields.forEach((field) => {
    if (!get(values, field) && get(values, field) !== 0) {
      set(errors, field, 'Required');
    }
  });

  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

export default validate;
