module.exports = {
  development: {
    protocol: 'http',
    httpServer: {
      host: 'localhost',
      port: 8080,
    },
  },
  maps: {
    API_KEY: 'AIzaSyBfM7TmQfp33a4l3orD3XIeZupcfScBCFk',
  },
};
