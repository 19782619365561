import React from 'react';
import ScrollableContainer from './ScrollableContainer';
import Tower3d from '../assets/images/road.png';


const OurJobs = () => (
  <ScrollableContainer
    componentId="our-jobs"
  >
    <div
      id="our-jobs"
    >
      <div className="content">
        <h2 className="header">What we do?</h2>
        <p>
          {/* eslint-disable-next-line max-len */}
          DEFINITECHS provides solution for engineering firms to automate the surveying process of infrastructure, such as roads, road assets, etc. Our solution enables them to significantly save cost and time while increasing the accuracy.
        </p>
      </div>
      <div className="images">
        <img id="our-jobs-img" src={Tower3d} alt="product" />
      </div>
    </div>
  </ScrollableContainer>
);

export default OurJobs;
