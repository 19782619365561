import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import activateTab from '../actions/activateTab';

class ScrollableContainer extends Component {
  componentDidMount() {
    this.handleScrolling();
    document.addEventListener('scroll', this.handleScrolling);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScrolling);
  }

  handleScrolling = () => {
    const { activateTab, activeTab, componentId } = this.props;
    const wrappedElement = document.getElementById(componentId);
    if (wrappedElement.getBoundingClientRect().top > (-1) * window.screen.height / 4
      && wrappedElement.getBoundingClientRect().top < window.screen.height / 4
      && activeTab !== componentId) {
      activateTab(componentId);
    }
  };

  render() {
    const { children } = this.props;
    return (
      <>
        {children}
      </>
    );
  }
}

ScrollableContainer.propTypes = {
  componentId: PropTypes.string.isRequired,
  activateTab: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ScrollableContainer.defaultProps = {
  initialState: false,
};

const mapStateToProps = state => ({
  activeTab: state.tab.activeTab,
});

export default connect(mapStateToProps, { activateTab })(ScrollableContainer);
