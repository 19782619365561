import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { withStyles } from '@material-ui/core/styles/index';
import Paper from '@material-ui/core/Paper/index';
import MaterialTabs from '@material-ui/core/Tabs';
import $ from 'jquery';
import { Button } from '@material-ui/core';
import Tab from './Tab';
import LogoImage from '../assets/images/logo.png';

import styles from '../assets/jss/tab';
import {Redirect, BrowserRouter as Router} from "react-router-dom";

class Tabs extends Component {
  state = {
    activeTabs: false,
    redirect: null,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY === 0) {
      this.setState({ activeTabs: false });
    } else {
      this.setState({ activeTabs: true });
    }
  };

  handleChange = (event, value) => {
    if (value === 'leadership') {
      this.setState({ redirect: '/leadership' });
      return;
    }
    if (window.location.pathname === '/leadership') {
      this.setState({ redirect: (`/#${value}`) });
      return;
    }
    const ref = document.getElementById(value);
    const top = ref.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 50;
    $('html, body').animate({
      scrollTop: top,
    }, 1000);
  };

  // eslint-disable-next-line consistent-return
  renderRedirect = () => {
    if (this.state.redirect === '/leadership' && window.location.pathname === '/') {
      return <Redirect to={this.state.redirect} />;
    }
    if (this.state.redirect !== '/leadership' && window.location.pathname === '/leadership') {
      return <Redirect to={this.state.redirect} />;
    }
  };

  render() {
    const { activeTabs } = this.state;
    const { classes, activeTab } = this.props;
    const { pathname } = window.location;
    return (
      <Paper
        square
        id="tabs"
        className={`${classes.tabs} ${(activeTabs || pathname !== '/') ? classes.activeTabs : ''}`}
      >
        {this.renderRedirect()}
        <Button onClick={() => this.handleChange(null, 'home')} className={classes.logo}>
          <img className={classes.logoImage} alt="Logo" src={LogoImage} />
          DEFINITECHS Inc.
        </Button>
        <MaterialTabs
          TabIndicatorProps={{ style: styles.tabIndicator }}
          value={activeTab}
          onChange={this.handleChange}
        >
          <Tab value="home" style={{ display: 'none' }} />
          <Tab value="about" style={{ display: 'none' }} />
          <Tab value="home" style={{ display: 'none' }} />
          <Tab active={(activeTab === 'our-jobs')} label="About" value="our-jobs" />
          <Tab active={(activeTab === 'product')} label="Solutions" value="product" />
          <Tab active={(activeTab === 'demo')} label="Demo" value="demo" />
          <Tab active={(activeTab === 'footer')} label="Contact Us" value="footer" />
          {/*<Tab active={(activeTab === 'leadership')} label="Leadership" value="leadership" />*/}
        </MaterialTabs>
        <div style={{ width: '180px' }} />
      </Paper>
    );
  }
}

Tabs.defaultProps = {
  classes: {},
};

Tabs.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object,
  activeTab: PropTypes.string.isRequired,
};


export default withStyles(styles)(Tabs);
