import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Note from '@material-ui/icons/Note';
import { connect } from 'react-redux';
import TextField from '../../fields/TextField';
import Form from '../Form';
import RemoteActions from '../RemoteActions';
import submit from '../../../actions/submitForm/demo';
import validate from './validate';

function DemoFrom(props) {
  const {
    submitted, pristine, reset, submitting,
  } = props;

  const containerProps = {
    className: 'demo-form__card',
    title: <Note />,
  };

  const actions = (
    <RemoteActions
      reset={reset}
      submitting={submitting}
      pristine={pristine}
      form="demoForm"
    />
  );

  return (
    <Form
      submitted={submitted}
      containerProps={containerProps}
      actions={actions}
    >
      <div className="demo-form">
        <div className="demo-form__info">
          <div className="demo-form__field">
            <Field
              label="Name"
              name="name"
              component={TextField}
              type="text"
              fullWidth
            />
          </div>
          <div className="demo-form__field">
            <Field
              label="Email"
              name="email"
              component={TextField}
              type="email"
              fullWidth
            />
          </div>
          <div className="demo-form__field demo-form__subject">
            <Field
              label="subject"
              name="subject"
              component={TextField}
              type="text"
              fullWidth
            />
          </div>
          <div className="demo-form__field demo-form__phone">
            <Field
              label="Phone"
              name="phone"
              component={TextField}
              type="number"
              fullWidth
            />
          </div>
        </div>
        <div className="demo-form__message">
          <Field
            label="message"
            name="message"
            component={TextField}
            type="text"
            fullWidth
            multiline
            rows="3"
          />
        </div>
      </div>
    </Form>
  );
}

DemoFrom.propTypes = {
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  submitted: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  submitted: state.demo.submitted,
});

export default connect(mapStateToProps)(reduxForm({
  form: 'demoForm',
  onSubmit: submit,
  validate,
})(DemoFrom));
