import React from 'react';
import ScrollableContainer from './ScrollableContainer';
import LeadershipCard from "./LeadershipCard";
import Typography from "@material-ui/core/Typography";


class Leadership extends React.Component{
  render(){
    return (
      <ScrollableContainer
        componentId="leadership"
      >
        <div id="leadership">
          <LeadershipCard
            imgLink="https://definitechs-website.s3.us-east-2.amazonaws.com/leadership/Square_Amin.jpg"
            name="Lizard"
            text="Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica"
            linkedInURL="https://www.linkedin.com/in/soheil-namvar-93052980/"
          />
          <LeadershipCard
            imgLink="https://definitechs-website.s3.us-east-2.amazonaws.com/leadership/Square_Amin.jpg"
            name="Lizard"
            text="Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica"
            linkedInURL="https://www.linkedin.com/in/soheil-namvar-93052980/"
          />
          <LeadershipCard
            imgLink="https://definitechs-website.s3.us-east-2.amazonaws.com/leadership/Square_Amin.jpg"
            name="Lizard"
            text="Lizards are a widespread group of squamate reptiles, with over 6,000 species, ranging
                across all continents except Antarctica"
            linkedInURL="https://www.linkedin.com/in/soheil-namvar-93052980/"
          />
        </div>
      </ScrollableContainer>
    );
  }
}

export default Leadership;