import React from 'react';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import PropTypes from 'prop-types';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = {
  float: 'right',
  margin: '0 20px 10px 0',
};

const SubmitButton = withStyles(() => ({
  root: {
    backgroundColor: '#336665',
    '&:hover': {
      backgroundColor: '#114e4d',
    },
  },
}))(Button);


function RemoteActions(props) {
  const {
    dispatch, submitting, pristine, reset, form,
  } = props;

  return (
    <CardActions style={styles}>
      <Button
        style={{
          borderRadius: '50vh',
        }}
        variant="contained"
        onClick={reset}
        disabled={submitting || pristine}
      >
Clean
      </Button>
      <SubmitButton
        style={{
          borderRadius: '50vh',
        }}
        variant="contained"
        color="primary"
        disabled={submitting || pristine}
        onClick={() => dispatch(submit(form))}
      >
Submit
      </SubmitButton>
    </CardActions>
  );
}

RemoteActions.propTypes = {
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  pristine: PropTypes.bool.isRequired,
  reset: PropTypes.func.isRequired,
  form: PropTypes.string.isRequired,
};

export default connect()(RemoteActions);
