import axios from '../../apis/apiclient';
import { DEMO_FORM_SUBMITTED } from '../../type/types';

export default (values, dispatch) => {
  const url = 'apis/send-email';
  const {
    subject, name, email, phone, address, message,
  } = values;
  // eslint-disable-next-line no-useless-concat
  const messageBody = `Name: ${name}\r\n` + `Email: ${email}\n` + `Phone: ${phone}\r\n` + `Address: ${address}\n` + `Message: ${message}\n`;
  axios.defaults.withCredentials = false;
  const sendSubject = `Request Demo (${subject})`;
  axios.post(url, {
    subject: sendSubject, message: messageBody,
  }).then(() => {
    dispatch({ type: DEMO_FORM_SUBMITTED });
  });
};
