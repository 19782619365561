import React, { Component } from 'react';
import ReactSwipe from 'react-swipe';
import $ from 'jquery';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ScrollableContainer from './ScrollableContainer';

const handleScroll = () => {
  const ref = document.getElementById('demo');
  const top = ref.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
  $('html, body').animate({
    scrollTop: top,
  }, 1000);
};

let reactSwipeEl;

const nextSlide = () => {
  reactSwipeEl.next();
};

const prevSlide = () => {
  reactSwipeEl.prev();
};

class Home extends Component {
  componentDidMount() {

  }

  render() {
    const swipeOptions = {
      startSlide: 0,
      speed: 1000,
      disableScroll: true,
      continuous: false,
    };
    return (
      <ScrollableContainer
        componentId="home"
      >
        <div
          id="home"
        >
          <ReactSwipe
            className="carousel"
            swipeOptions={swipeOptions}
            ref={(el) => {
              reactSwipeEl = el;
            }}
          >
            <div className="pane" id="pane-1">
              <h2 className="pane__header">AUTOMATIC INTELLIGENT SURVEYING</h2>
              <Button
                className="pane__btn"
                onClick={handleScroll}
                variant="contained"
                color="secondary"
                id="request-demo-btn"
              >
                Request Demo
              </Button>
            </div>
          </ReactSwipe>
          <IconButton
            id="left-slide"
            aria-label="Delete"
            onClick={prevSlide}
          >
            <ChevronLeft style={{ fill: 'white' }} className="slider-icon" />
          </IconButton>
          <IconButton
            id="right-slide"
            aria-label="Delete"
            onClick={nextSlide}
          >
            <ChevronRight style={{ fill: 'white' }} className="slider-icon" />
          </IconButton>
          <p id="topo3d-ref">Point-cloud taken by Topo3D</p>
        </div>
      </ScrollableContainer>
    );
  }
}

export default Home;
