import { ACTIVATE_TAB } from '../type/types';

const activateTab = tab => (dispatch) => {
  dispatch({
    type: ACTIVATE_TAB,
    payload: tab,
  });
};


export default activateTab;
