import React from 'react';
import DemoFrom from './froms/demo/DemoForm';
import ScrollableContainer from './ScrollableContainer';

const Demo = () => (
  <ScrollableContainer
    componentId="demo"
  >
    <div id="demo">
      <h2 className="header">Request Demo</h2>
      <DemoFrom />
    </div>
  </ScrollableContainer>
);

export default Demo;
