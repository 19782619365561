import React from 'react';
import CopyRight from '@material-ui/icons/Copyright';
import LocationOn from '@material-ui/icons/LocationOn';
import ContactMail from '@material-ui/icons/Mail';
import SupportMail from '@material-ui/icons/ContactMail';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import LinkedIn from '../assets/images/linkedin.svg';

const btnStyle = {
  marginRight: '10px',
  marginTop: '10px',
  width: '140px',
  borderRadius: '50vh',
  borderColor: 'white',
  color: 'white',
};

const fabStyle = {
  marginRight: '10px',
  backgroundColor: '#757575',
  color: 'rgba(0,0,0,0.52)',
  marginTop: '10px',
};

const Footer = () => (
  <footer id="footer">
    <div className="footer-components">
      <div className="footer-box">
        <div className="footer-box__group">
          <Fab style={fabStyle} disabled>
            <LocationOn className="footer__icon" />
          </Fab>
          <p className="footer-box__item">
1250 Guy St Suite #600,
            <br />
            <b>Montreal, QC H3H 2L3</b>
          </p>
        </div>
        <div className="footer-box__group">
          <Fab style={fabStyle} disabled>
            <ContactMail className="footer__icon" />
          </Fab>
          <a href="mailto:info@defnitechs.com" className="footer-box__item"><b>info@definitechs.com</b></a>
        </div>
        <div className="footer-box__group">
          <Fab style={fabStyle} disabled>
            <SupportMail className="footer__icon" />
          </Fab>
          <p> For client support:
            <br />
            <a href="mailto:support@defnitechs.com" className="footer-box__item"><b>support@definitechs.com</b></a>
          </p>
        </div>
      </div>
      {/*<div className="footer-box">*/}
      {/*  <div className="footer-box__item">*/}
      {/*    <a href="#about">*/}
      {/*      <Button style={btnStyle} variant="outlined">About</Button>*/}
      {/*    </a>*/}
      {/*    <a href="#product">*/}
      {/*      <Button style={btnStyle} variant="outlined">Service</Button>*/}
      {/*    </a>*/}
      {/*    <a href="#demo">*/}
      {/*      <Button style={btnStyle} variant="outlined">Demo</Button>*/}
      {/*    </a>*/}
      {/*    <a href="#footer">*/}
      {/*      <Button style={btnStyle} variant="outlined">Contact Us</Button>*/}
      {/*    </a>*/}
      {/*  </div>*/}

      {/*</div>*/}
    </div>
    <div id="copyright">
      <div className="definitechs-info">
        <div>
          <CopyRight />
          <p>2019, Created By Definitechs Inc.</p>
        </div>
        <div className="footer-box__item" id="social">
          <a href="https://www.linkedin.com/company/definitechs/about/">
            <img id="linked-in" src={LinkedIn} alt="linked-in" />
          </a>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
